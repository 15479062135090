import React from "react";
import { graphql } from "gatsby";

import { IndexPageTemplate } from "../templates";
import { Layout } from "../components";

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <IndexPageTemplate {...frontmatter} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        headerImage {
          cloudinaryMedia
          assetType
          deliveryType
          urlRemainder
          sourceURL
        }
        heroTitle
        heroText
        clientLogoTitle
        clientLogo {
          logo {
            cloudinaryMedia
            assetType
            deliveryType
            urlRemainder
            sourceURL
          }
        }
        heroVideo {
          cloudinaryMedia
          assetType
          deliveryType
          urlRemainder
          sourceURL
        }
        featuresBody
        featuresVideo {
          cloudinaryMedia
          assetType
          deliveryType
          urlRemainder
          sourceURL
        }
        features {
          title
          body
        }
        featureButtonOne
        featureButtonOneLink
        featureButtonTwo
        featureButtonTwoLink
        globeTitle
        globeBody
        globeVideo {
          cloudinaryMedia
          assetType
          deliveryType
          urlRemainder
          sourceURL
        }
      }
    }
  }
`;
